






















import { SfSearchBar } from '@storefront-ui/vue';
import {
  defineComponent, watch, useRoute, useContext, onMounted, ref
} from '@nuxtjs/composition-api';
import { useSearch } from '@wemade-vsf/search';

export default defineComponent({
  name: 'SearchBar',
  components: {
    SfSearchBar
  },

  props: {
    itemsPerPage: {
      type: Number,
      default: 12,
    },
    minTermLen: {
      type: Number,
      default: 3,
    },
    openOnFocus: {
      type: Boolean,
      default: true
    },
    focusOnView: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { app } = useContext();
    const searchInput = ref<HTMLInputElement | null>(null);
    const {
      search,
      setSearchTerm,
      isSearchOpen,
      term,
      openSearch,
      closeSearch
    } = useSearch();

    const route = useRoute();

    const toggleSearch = () => {
      if (isSearchOpen.value) {
        openSearch();
      } else {
        closeSearch();
      }
    };

    function doCloseSearch() {
      closeSearch();
      emit('close')
    }

    const rawHandleSearch = async () => {
      if (term.value.length < props.minTermLen) return;
      if (app.$gtag) {
        app.$gtag('event', 'search', {
          'search_term': term.value
        })
      }
      await search();
    };

    let searchTimeout: any = null;
    const debouncedHandleSearch = () => {
      const searchTerm = searchInput.value?.value ?? '';
      setSearchTerm(searchTerm)
      if (!props.openOnFocus) {
        if (searchTerm && !isSearchOpen.value) {
          openSearch()
        } else if (!searchTerm) {
          closeSearch()
        }
      }
      if (searchTimeout) {
        clearTimeout(searchTimeout);
        searchTimeout = null
      }
      searchTimeout = setTimeout(rawHandleSearch, 500)
    }

    const handleKeydownEnter = (searchTerm: string) => {
      setSearchTerm(searchTerm)
      // cancel debounce timeout started by typing into the searchbar - this is to avoid making two network requests instead of one
      if (searchTimeout) {
        clearTimeout(searchTimeout);
        searchTimeout = null
      }
      rawHandleSearch();
    };

    function maybeOpenSearch () {
      if (props.openOnFocus) {
        openSearch()
      }
    }

    onMounted(() => {
      if (props.focusOnView && searchInput.value) {
        searchInput.value.focus();
      }
    })

    watch(route, () => {
      closeSearch();
    });

    return {
      doCloseSearch,
      maybeOpenSearch,
      searchInput,
      isSearchOpen,
      toggleSearch,
      rawHandleSearch,
      debouncedHandleSearch,
      handleKeydownEnter,
      term,
    };
  },
});

